export default {
  form: {
    chat: 0,
    action: null,
    pickUpDate: "",
    dropAtCtsDate: "",
    dropOffDate: "",
    pickUpHours: null,
    manifestId: null,
    receiverLicense: null,
    shipperLicense: null,
    receiverAddress: null,
    receiverCity: null,
    extDriverName: null,
    extDriverBadge: null,
    extDriverLicense: null,
    extVehicleMake: null,
    extVehicleModel: null,
    extPhone: null,
    extVehicleLicensePlate: null,
    receiverState: null,
    receiverZip: null,
    packageName: null,
    packagePrice: null,
    packages: [],
    payment_terms: [],
    shipment_shipping_boxes: [],
    shipment_shipping_boxes_value: {},
    ctsQuestions: [],
  },
};
