import { store } from "quasar/wrappers";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

//app flags
import app from "./app";

//app authentication
import auth from "./auth";

//app system data
import data from "./data";

//app notifications
import notifications from "./notifications";

//current filters
import current from "./current";

import cart from "./cart";

import inventory from "./inventory";

//add brand form data
import brand from "./brand";

//add product form data
import product from "./product";

//add listing form data
import listing from "./listing";

//add buyer request form data
import buyerRequest from "./buyer-request";

//listing V2
import listingV2 from "src/store/listingV2";

//filters applied via filter panel
import panelFilters from "./panel-filters";

//Shipment
import shipment from "src/store/shipment";

//Inventory Reorder
import inventoryReorder from "src/store/inventory-reorder";

export default store(function (/* { ssrContext } */) {
  const COOKIE_DOMAIN = process.env.COOKIE_STORE_DOMAIN;
  const COOKIE_NAME = process.env.COOKIE_STORE_NAME;
  const COOKIE_LIFETIME = process.env.COOKIE_LIFETIME; //in minutes.
  const IS_WEB_APP = process.env.APP_TYPE === "web";

  return createStore({
    modules: {
      auth,
      app,
      data,
      current,
      listing,
      product,
      brand,
      notifications,
      buyerRequest,
      listingV2,
      panelFilters,
      shipment,
      inventoryReorder,
      cart,
      inventory,
    },
    plugins: [
      //User data will be saved in cookies.
      createPersistedState({
        key: `${COOKIE_NAME}-auth`,
        paths: ["auth"],
        storage: IS_WEB_APP
          ? {
              //use cookies for auth only in web app.
              getItem: (key) => Cookies.get(key, { domain: COOKIE_DOMAIN }),
              setItem: (key, value) => {
                let cookieExpiresAt = new Date(
                  new Date().getTime() + COOKIE_LIFETIME * 60 * 1000
                ); //add in milliseconds.
                Cookies.set(key, value, {
                  expires: cookieExpiresAt,
                  secure: location.protocol === "https:",
                  domain: COOKIE_DOMAIN,
                });
              },
              removeItem: (key) =>
                Cookies.remove(key, { domain: COOKIE_DOMAIN }),
            }
          : null,
      }),
      //User notifications will be saved in localStorage.
      createPersistedState({
        key: `${COOKIE_NAME}-notifications`,
        paths: ["notifications"],
      }),
      //Data for new listing will be saved in localStorage.
      createPersistedState({
        key: `${COOKIE_NAME}-listing`,
        paths: ["listing"],
      }),
      //Data for new product will be saved in localStorage.
      createPersistedState({
        key: `${COOKIE_NAME}-product`,
        paths: ["product"],
      }),
      //Data for new brand will be saved in localStorage.
      createPersistedState({
        key: `${COOKIE_NAME}-brand`,
        paths: ["brand"],
      }),
      //Data for current app state will be saved in localStorage.
      createPersistedState({
        key: `${COOKIE_NAME}-current`,
        paths: ["current"],
      }),
      //Data for new buyer request will be saved in localStorage.
      createPersistedState({
        key: `${COOKIE_NAME}-buyer-request`,
        paths: ["buyerRequest"],
      }),
      //Data for listing V2.
      createPersistedState({
        key: `${COOKIE_NAME}-listing-v2`,
        paths: ["listingV2"],
      }),
    ],

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING,
  });
});
