export function setBrands(state, brands) {
  let tmpBrands = [];
  brands.forEach((brand) => {
    if (!brand.is_archived) {
      tmpBrands.push({
        id: brand.id,
        name: brand.name,
        code: brand.code,
        logo_url: brand.logo_url,
        banner_url: brand.banner_url,
      });
    }
  });
  state.brands = tmpBrands;
}
export function setAllBrands(state, brands) {
  const brandsMap = {};
  brands.forEach((brand) => {
    if (!brand.is_archived) {
      brandsMap[brand.id] = {
        id: brand.id,
        name: brand.name,
        code: brand.code,
        logo_url: brand.logo_url,
        banner_url: brand.banner_url,
        isUserBrand: false,
      };
    }
  });
  state.allBrands = brandsMap;
}
export function setUserBrands(state, userBrandIds) {
  userBrandIds.forEach((id) => {
    if (state.allBrands[id]) {
      state.allBrands[id].isUserBrand = true;
    }
  });
}
export function setBrandDetails(state, { brandId, details }) {
  state.brandDetails[brandId] = details;
}
export function setAuctions(state, auctions) {
  state.auctions = auctions;
}
export function setListings(state, listings) {
  state.listings = listings;
}
export function setAuctionsForBrand(state, { brandId, auctions }) {
  state.auctionsByBrand[brandId] = auctions;
}
export function setListingsForBrand(state, { brandId, listings }) {
  state.listingsByBrand[brandId] = listings;
}
export function setCategoryImages(state, category_images) {
  state.category_images = category_images;
}
export function setCategoryTypes(state, category_types) {
  state.category_types = category_types;
}
export function setCategories(state, categories) {
  state.categories = categories;
}
export function setFilterCategories(state, categories) {
  state.inventory_filter_categories = categories;
}
export function setTrimTypes(state, trim_types) {
  state.trim_types = trim_types;
}
export function setStrainTypes(state, strain_types) {
  state.strain_types = strain_types;
}
export function setIngredients(state, ingredients) {
  state.ingredients = ingredients;
}
export function setProductionTypes(state, production_types) {
  state.production_types = production_types;
}
export function setProducts(state, products) {
  state.products = products;
}
export function setLicenses(state, licenses) {
  state.licenses = licenses;
}
export function updateLicense(state, updated_license) {
  let index = state.licenses.findIndex(
    (license) => license.id === updated_license.id
  );
  if (index >= 0) {
    state.licenses[index] = updated_license;
  } else {
    state.licenses.push(updated_license);
  }
}
export function addLicense(state, license) {
  state.licenses.push(license);
}
export function removeLicense(state, license_to_remove) {
  let index = state.licenses.findIndex(
    (license) => license.id === license_to_remove.id
  );
  if (index >= 0) {
    state.licenses.splice(index, 1);
  }
}
export function addBrand(state, brand) {
  state.brands.push(brand);
}
export function addProduct(state, product) {
  state.products.push(product);
}
export function updateProduct(state, updated_product) {
  let index = state.products.findIndex(
    (product) => product.id === updated_product.id
  );
  if (index >= 0) {
    state.products[index] = updated_product;
  } else {
    state.products.push(updated_product);
  }
}

export function setCompany(state, value) {
  state.company = value;
}
//export function setLicenses(state, licenses) {
//  state.licenses = licenses;
//}
//export function addLicense(state, license) {
//  state.licenses.push(license);
//}
//export function removeLicense(state, licenseId) {
//  let index = state.licenses.findIndex((license) => license.id === licenseId);
//  if (index >= 0) {
//    state.licenses.splice(index, 1);
//  }
//}
export function setCompanyContacts(state, value) {
  state.company_contacts = value;
}
export function setOrderStatuses(state, value) {
  state.order_statuses = value;
}
export function setOrderSources(state, value) {
  state.order_sources = value;
}
export function setOrderTypes(state, value) {
  state.order_types = value;
}
export function updateOrAddCompanyContact(state, contact) {
  let index = state.company_contacts.findIndex((c) => c.id === contact.id);
  if (index >= 0) {
    state.company_contacts[index] = contact;
  } else {
    state.company_contacts.push(contact);
  }
}
export function removeCompanyContact(state, contactId) {
  let index = state.company_contacts.findIndex((c) => c.id === contactId);
  if (index >= 0) {
    state.company_contacts.splice(index, 1);
  }
}
export function setUsers(state, users) {
  state.users = users;
}
export function addUser(state, user) {
  state.users.push(user);
}
export function removeUser(state, user_id) {
  let index = state.users.findIndex((user) => user.id === user_id);
  if (index >= 0) {
    state.users.splice(index, 1);
  }
}
export function addTempBox(state, box) {
  state.company.tempBoxes.push(box);
}
export function editTempBox(state, box) {
  let index = state.company.tempBoxes.findIndex((el) => (el.id = box.id));
  state.company.tempBoxes[index] = box;
}
export function deleteTempBox(state, box) {
  let index = state.company.tempBoxes.findIndex((el) => (el.id = box.id));
  state.company.tempBoxes.splice(index, 1);
}
export function clearTempShippingBoxes(state) {
  state.company.tempBoxes = [];
}
export function setPaymentTerms(state, paymentTerms) {
  state.payment_terms = paymentTerms;
}
export function setGrowMedia(state, growMedia) {
  state.grow_media = growMedia;
}
export function setLocationNames(state, locationName) {
  state.location_names = locationName;
}
export function setInventoryCategoryTypes(state, categoryTypes) {
  state.inventory_category_types = categoryTypes;
}
export function setLoading(state, isLoading) {
  state.isLoadingData = isLoading;
}
