import { $API } from "boot/api";

export function addItemToCart({ commit, state, rootState }, itemData) {
  commit("addItem", itemData);
}

export function clearItems({ commit }) {
  commit("clearItems");
}

export function clearAllData({ dispatch }) {
  dispatch("clearItems");
}

export function removeItemFromCart({ commit, state }, itemId) {
  let itemIndex = state.listings.findIndex(
    (i) => i.inventory_listing === itemId
  );
  if (itemIndex >= 0) {
    commit("removeItem", itemIndex);
  }
}
