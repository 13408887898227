import { boot } from "quasar/wrappers";

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(async ({ store, router, redirect }) => {
  const authPageUrl = `${process.env.API_BASE_URL}/login`;
  //Check auth status if user was previously authorized.
  if (store.state.auth.is_user_logged_in) {
    await store.dispatch("auth/checkAuth");
  }
  //Dev only auth if regular is not authorized.
  if (!store.state.auth.is_user_logged_in && process.env.DEV) {
    await store.dispatch("auth/devAuth");
  }
  if (store.state.auth.is_user_logged_in) {
    //Sync count of active listings in user's watchlist.
    store.dispatch("auth/syncActiveWatchlistCount");
  }

  router.beforeEach((to, from, next) => {
    // //If user is not authenticated redirect to AUTH page.
    // if (!store.state.auth.is_user_logged_in) {
    //   redirect(authPageUrl);
    // } else {
    //   next();
    // }
    //If it's a public page, then do nothing
    //If user is not authenticated and attempts to open app page redirect to to auth home page
    //If user is authenticated and attempts to open auth page redirect to to app home page
    if (to.meta.public_page) {
      next();
    } else if (!store.state.auth.is_user_logged_in && !to.meta.auth_page) {
      next({name: "auth-login"});
    } else if (store.state.auth.is_user_logged_in && to.meta.auth_page) {
      next({name: "auctions-browse"});
    } else {
      next();
    }
  });
});
