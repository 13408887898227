export function addItem(state, itemData) {
  state.items.push({
    product: itemData.item.product,
    id: itemData.item.id,
    name: itemData.item.name,
    category_type_name: itemData.item.category_type?.name,
    target_price: itemData.price_per_unit,
    target_price_total: itemData.price,
    unit_price: itemData.price_per_unit,
    total_price: itemData.price,
    inventory_value: itemData.inventory_value,
    inventory_unit: itemData.inventory_unit,
    last_order_value: itemData.inventory_value,
    last_order_unit: itemData.inventory_unit,
    last_ordered_at: itemData.last_ordered_at,
    last_unit_price: itemData.price_per_unit,
    last_unit_type: itemData.inventory_unit,
    supplier: {
      license_number: itemData.supplier_license_number,
      name: itemData.supplier_name,
      is_registered: itemData.supplier_is_registered
    },
    supplier_product: itemData.supplier_product,
    current_inventory_value: itemData.current_inventory_value,
    current_inventory_unit: itemData.current_inventory_unit
  });
}

export function removeItem(state, itemIndex) {
  state.items.splice(itemIndex, 1);
}

export function clearItems(state) {
  state.items = [];
}

export function setItemFieldValue(state, payload) {
  state.items[payload.index][payload.name] = payload.value;
}

export function addSupplierInfo(state, supplierData) {
  if (typeof state.suppliersInfo[supplierData.license_number] === 'undefined') {
    state.suppliersInfo[supplierData.license_number] = {
      name: supplierData?.name,
      email:supplierData?.email,
      phone: supplierData?.phone
    }
  }
}

export function clearSuppliersInfo(state) {
  state.suppliersInfo = {};
}

export function setSupplierInfoFieldValue(state, payload) {
  state.suppliersInfo[payload.license_number][payload.name] = payload.value;
}

export function setSupplierPaymentTerms(state, payload) {
  state.supplierPaymentTerms[payload.license_number] = payload.value;
}

export function setItemSales(state, payload) {
  state.itemSales[payload.item_id] = payload.sales;
}

export function clearItemSales(state) {
  state.itemSales = [];
}
