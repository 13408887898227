export function addItem(state, itemData) {
  state.listings.push({
    recurring_end_date: "",
    recurring_frequency: "1_week",
    recurring_interval: "week",
    recurring_period: 1,
    recurring: false,
    is_recurring: false,
    inventory_listing: itemData.inventory_listing,
    inventory_listing_item: itemData.inventory_listing_item,
    quantity_requested: itemData.quantity_requested,
    inventory_unit: itemData.inventory_unit,
    price: itemData.price,
    paymentTerms: itemData.paymentTerms,
    item_title: itemData.item_title,
    image: itemData.image,
  });
  state.listings = [...state.listings];
}

export function incrementQuantity(state, itemIndex) {
  console.log("incrememnt", state.listings[itemIndex]);
  state.listings[itemIndex].quantity_requested++;
  state.listings = [...state.listings];
}

export function decrementQuantity(state, itemIndex) {
  console.log("decrementQuantity", state.listings[itemIndex]);
  state.listings[itemIndex].quantity_requested--;
  state.listings = [...state.listings];
}

export function removeItem(state, itemIndex) {
  state.listings.splice(itemIndex, 1);
  state.listings = [...state.listings];
}

export function clearItems(state) {
  state.listings = [];
}

export function setItemFieldValue(state, payload) {
  state.listings[payload.index][payload.name] = payload.value;
}
