export function getFormData(state) {
  const {
    license,
    title,
    description,
    inventory_value,
    inventory_unit,
    category_type,
    category,
    strain_type,
    production_type,
    max_price,
    image
  } = state.form;

  return {
    license: license?.id,
    title,
    description,
    inventory_value,
    inventory_unit,
    image,
    category_type: category_type?.id,
    category: category?.id,
    strain_type: strain_type?.id,
    production_type: production_type?.id,
    max_price
  };
}
