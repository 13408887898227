export default function () {
  return {
    filters: {
      min_price: null,
      max_price: null,
      category_types: [],
      categories: [],
      strain_types: [],
      strains: [],
      production_types: [],
      location_names: [],
      inventory_category_types: [],
    },
  };
}
