import { API_ROUTES } from "src/router/api";
import { boot } from "quasar/wrappers";
import axios from "axios";
import moment from "moment-timezone";
import store from "src/store";

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

const API_BASE = process.env.API_BASE_URL;

const api = axios.create({ baseURL: API_BASE });

// const route = (name, call, params) => {
//   let route = API_ROUTES[name];

//   if (call instanceof Object) {
//     params = call;
//   } else if (call) {
//     route = `${route}/${call}`;
//   }

//   return [route, params];
// };

const route = (name, call, params) => {
  let route = API_ROUTES[name];

  if (call instanceof Object) {
    params = call;
  } else if (call) {
    call = call.toString();
    if (!route.endsWith("/") && !call.startsWith("/") && !route.endsWith("=")) {
      route += "/";
    }
    route += call;
  }

  return [route, params];
};

const handleNoAuth = () => {
  //dispatch custom unauthenticatedApiResponse event.
  const event = new Event("unauthenticatedApiResponse");
  document.dispatchEvent(event);
};

const $API = Object.keys(API_ROUTES).reduce((rtn, name) => {
  rtn[name] = {
    GET(call, params) {
      [call, params] = route(name, call, params);

      let result = api.get(call, { params });

      result.catch(({ response }) => {
        if (401 === response.status) {
          handleNoAuth();
        }
      });

      return result;
    },
    POST(call, params) {
      [call, params] = route(name, call, params);

      let result = api.post(call, params);

      result.catch(({ response }) => {
        if (401 === response.status) {
          handleNoAuth();
        }
      });

      return result;
    },
    PUT(call, params) {
      [call, params] = route(name, call, params);

      let result = api.put(call, params);

      result.catch(({ response }) => {
        if (401 === response.status) {
          handleNoAuth();
        }
      });

      return result;
    },
    DELETE(call) {
      [call] = route(name, call);

      let result = api.delete(call);

      result.catch(({ response }) => {
        if (401 === response.status) {
          handleNoAuth();
        }
      });

      return result;
    },
  };

  return rtn;
}, {});

export default boot(({ app, store }) => {
  //Set Authorization header and other required for API.
  api.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${store.state.auth.token}`;
  api.defaults.headers.common["Accept"] = "application/json";
  api.defaults.headers.common["Content-Type"] = "application/json";
  api.defaults.headers.common["Timezone"] = moment.tz.guess();

  app.config.globalProperties.$API = $API;
});

export { api, $API };
