import { $API } from "boot/api";

export function uploadFormFile({ commit }, { prop, value }) {
  let formData = new FormData();

  formData.append("file", value, value.name);

  return $API.CURRENT_USER_ATTACHMENTS.POST(formData)
    .then(({ data }) => {
      commit("setFormValue", { prop, value: data.file });

      return {
        success: true,
        image: { name: data.file.name, url: data.file.url },
      };
    })
    .catch(({ response: { data } }) => {
      return {
        success: false,
        message: "Failed to upload image",
      };
    });
}
export function deleteFormFile({ commit, state }, { prop }) {
  let file = state.form[prop];
  let formData = {
    file_name: file.name,
  };

  return $API.CURRENT_USER_ATTACHMENTS_DELETE.POST(formData)
    .then(() => {
      commit("setFormValue", { prop, value: null });

      return {
        success: true,
      };
    })
    .catch(({ response: { data } }) => {
      return {
        success: false,
        message: "Failed to delete image",
      };
    });
}

export function submitFormData({ commit, getters }) {
  return $API.BUYER_REQUESTS.POST("", getters.getFormData).then((data) => {
    commit("clearFormData");

    return data;
  });
}

export function logout({ commit }) {
  commit("clearFormData");
}
