export default function() {
  return {
    form: {
      id: null,
      chat: 0,
      is_edit_product: false,
      listing_draft_id: null,
      save_draft: false,

      //product fields
      product: null,
      title: "",
      description: "",
      inventory_value: 0,
      inventory_unit: "lb",
      images: [],

      //auction fields
      start_date: "",
      start_price: 0,
      days_duration: 10,
      is_auto_relist: false,
      is_reserve_price: false,
      reserve_price: 0,
      is_buy_now_price: false,
      buy_now_price: 0,
      is_promoted: false,
      tags: [],
      bids_count: 0,
      is_finished: false,
      shipping_boxes: [],
      shipping_boxes_value: {},
      payment_terms: [],
      grow_media: [],
    },
  };
}
