export function getFormData(state) {
  return state.form;
}

export function getTransferFormData(state) {
  return extractActionData(getFormData(state), "transfer_");
}

export function extractActionData(formData, fieldKey) {
  let data = {},
    keyLength = fieldKey.length;

  for (let key in formData) {
    if (key.indexOf(fieldKey) === 0) {
      data[key.substring(keyLength)] = formData[key];
    }
  }

  return data;
}
