export function setNotifications(state, notifications) {
  state.notifications = notifications;
}
export function updateNotification(state, updatedNotification) {
  let index = state.notifications.findIndex(
    (notification) => notification.id === updatedNotification.id
  );
  if (index >= 0) {
    state.notifications[index] = updatedNotification;
  } else {
    state.notifications.push(updatedNotification);
  }
}
export function searchNotifications(state, search) {
  state.searchValue = search;
}
