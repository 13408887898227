export function setFormValue(state, { prop, value }) {
  state.form[prop] = value;
}

export function mapFormData(state, data) {
  state.form.id = data.id;
  state.form.title = data.name;
  state.form.logo = data.logo;
  state.form.chat = NaN;
  state.form.banner = data.banner;
}
export function clearFormData(state) {
  state.form = {
    chat: 0,
    title: "",
    logo: null,
    banner: null,
  };
}
