export default {
  name: "icons",
  type: {
    positive: "check_circle",
    negative: "warning",
    info: "info",
    warning: "priority_high",
  },
  arrow: {
    up: "icon-up",
    right: "icon-right",
    down: "icon-down",
    left: "icon-left",
    dropdown: "icon-down",
  },
  chevron: {
    left: "icon-left",
    right: "icon-right",
  },
  colorPicker: {
    spectrum: "gradient",
    tune: "tune",
    palette: "style",
  },
  pullToRefresh: {
    icon: "refresh",
  },
  carousel: {
    left: "icon-left",
    right: "icon-right",
    up: "keyboard_arrow_up",
    down: "keyboard_arrow_down",
    navigationIcon: "lens",
  },
  chip: {
    remove: "icon-cancel",
    selected: "icon-check",
  },
  datetime: {
    arrowLeft: "icon-left",
    arrowRight: "icon-right",
    now: "icon-time",
    today: "icon-date",
  },
  editor: {
    bold: "icon-editor-bold",
    italic: "icon-editor-italic",
    strikethrough: "icon-editor-strike",
    underline: "icon-editor-underline",
    unorderedList: "icon-editor-list-bullets",
    orderedList: "icon-editor-list-numbers",
    subscript: "vertical_align_bottom",
    superscript: "vertical_align_top",
    hyperlink: "icon-editor-link",
    toggleFullscreen: "fullscreen",
    quote: "icon-editor-quote",
    left: "icon-editor-align-left",
    center: "icon-editor-align-center",
    right: "icon-editor-align-right",
    justify: "icon-editor-align-justify",
    print: "print",
    outdent: "icon-editor-outdent",
    indent: "icon-editor-indent",
    removeFormat: "icon-cancel",
    formatting: "icon-edotor-size",
    fontSize: "icon-editor-size",
    align: "icon-editor-align-left",
    hr: "icon-editor-hr",
    undo: "icon-editor-undo",
    redo: "icon-editor-redo",
    heading: "icon-editor-size",
    code: "icon-editor-code",
    size: "icon-editor-size",
    font: "font_download",
    viewSource: "icon-editor-code",
  },
  expansionItem: {
    icon: "keyboard_arrow_down",
    denseIcon: "icon-down",
  },
  fab: {
    icon: "icon-plus",
    activeIcon: "icon-close",
  },
  field: {
    clear: "icon-cancel",
    error: "icon-warning",
  },
  pagination: {
    first: "first_page",
    prev: "keyboard_arrow_left",
    next: "keyboard_arrow_right",
    last: "last_page",
  },
  rating: {
    icon: "grade",
  },
  stepper: {
    done: "icon-check",
    active: "icon-edit",
    error: "icon-warning",
  },
  tabs: {
    left: "icon-left",
    right: "icon-right",
    up: "keyboard_arrow_up",
    down: "keyboard_arrow_down",
  },
  table: {
    arrowUp: "icon-up",
    warning: "icon-warning",
    firstPage: "first_page",
    prevPage: "icon-left",
    nextPage: "icon-right",
    lastPage: "last_page",
  },
  tree: {
    icon: "play_arrow",
  },
  uploader: {
    done: "done",
    clear: "clear",
    add: "add_box",
    upload: "cloud_upload",
    removeQueue: "clear_all",
    removeUploaded: "done_all",
  },
};
