export default function () {
  return {
    allBrands: {},
    auctionsByBrand: {},
    listingsByBrand: {},
    brandDetails: {},
    brands: [],
    category_images: [],
    category_types: [],
    categories: [],
    inventory_filter_categories: [],
    trim_types: [],
    strain_types: [],
    ingredients: [],
    production_types: [],
    licenses: [],
    payment_terms: [],
    grow_media: [],
    company: {
      name: "",
      ein: "",
      logo_url: "",
      status: {
        name: "",
      },
      created_on: "",
      tempBoxes: [],
    },
    company_contacts: [],
    users: [],
    location_names: [],
    inventory_category_types: [],
    isLoadingData: true,
    listings: [],
    auctions: [],
    order_statuses: [],
    order_sources: [],
    order_types: [],
  };
}
