export function getFormData(state) {
  const {
    //listing
    title,
    description,
    images,
    tags,
    shipping_boxes,
    shipping_boxes_value,

    //auction
    days_duration,
    inventory_value,
    inventory_unit,

    //pricing
    start_price,
    reserve_price,
    buy_now_price,

    //flags
    is_auto_relist,
    is_reserve_price,
    is_buy_now_price,
    is_promoted,
    save_draft,

    //relationships
    product,
    license,
    listing_draft_id,
    payment_terms,
    grow_media,
  } = state.form;

  return {
    //listing
    title,
    description,
    images,
    tags,
    shipping_boxes,
    shipping_boxes_value,

    //auction
    days_duration,
    inventory_value,
    inventory_unit,

    //pricing
    start_price,
    reserve_price,
    buy_now_price,

    //flags
    is_auto_relist,
    is_reserve_price,
    is_buy_now_price,
    is_promoted,
    save_draft,

    //relationships
    product:           product.id,
    license:           product.license.id,
    brand:             product.brand.id,
    category:          product.category.id,
    category_type:     product.category_type.id,
    strain_type:       product.strain_type.id,
    production_type:   product.production_type.id,
    listing_draft_id,
    payment_terms,
    grow_media,
  };
}
