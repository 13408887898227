export default function() {
  return {
    form: {
      id: null,
      chat: 0,
      license: null,
      title: "",
      description: "",
      inventory_value: 0,
      inventory_unit: "lb",
      image: null,
      category_type: null,
      category: null,
      strain_type: null,
      production_type: null,
      max_price: null
    },
  };
}
