export function setMenu(state, open) {
  state.menu = !!open;
}
export function toggleMenu(state) {
  state.menu = !state.menu;
}
export function setFilters(state, open) {
  state.filters = !!open;
}
export function toggleFilters(state) {
  state.filters = !state.filters;
}
export function setPanel(state, open) {
  state.panel = open;
}
export function togglePanel(state, open) {
  state.panel = state.panel === open ? null : open;
}
export function closeAllSidebars(state) {
  state.panel = null;
  state.menu = false;
  state.filters = false;
}
