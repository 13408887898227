import { api, $API } from "boot/api";
import Cookies from "js-cookie";

let checkAuthCookieInterval = null;

export function checkAuthCookie({ dispatch }) {
  const COOKIE_DOMAIN = process.env.COOKIE_STORE_DOMAIN;
  const COOKIE_NAME = process.env.COOKIE_STORE_NAME;

  let authCookie = Cookies.get(`${COOKIE_NAME}-auth`, { domain: COOKIE_DOMAIN });
  if (!authCookie) {
    //Auth cookie expired, dispatch logout.
    dispatch('logout');
    //Navigate to login page.
    window.location.replace('/auth/login');
  } else {
    //Auth cookie OK.
  }
}

export function maybeStartCheckAuthCookieInterval({ dispatch }) {
  //Check authentication cookie status only for a web app.
  const IS_WEB_APP = process.env.APP_TYPE === 'web';
  if (IS_WEB_APP) {
    //Check authentication cookie status every 30 seconds.
    checkAuthCookieInterval = setInterval(() => {
      dispatch("checkAuthCookie");
    }, 30 * 1000);
  }
}

export async function checkAuth({ commit, dispatch }) {
  await $API.CURRENT_USER.GET()
    .then(({ data }) => {
      commit("setIsUserLoggedIn", true);
      //Always set updated user data.
      commit("setUser", data.user);

      //User has signed in with active session.
      //Start check auth cookie status for web app.
      dispatch("maybeStartCheckAuthCookieInterval");
    })
    .catch(({ response }) => {
      if (401 === response.status) {
        commit("setIsUserLoggedIn", false);
        commit("setUser", {});
      }
    });
}
export function login({ commit, dispatch }, data) {
  commit("setIsUserLoggedIn", true);
  commit("setUser", data.user);
  commit("setToken", data.token);
  api.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

  //User has signed in with a new session.
  //Start check auth cookie status for web app.
  dispatch("maybeStartCheckAuthCookieInterval");
}
export function logout({ commit }) {
  commit("setIsUserLoggedIn", false);
  commit("setUser", false);
  commit("clearToken");

  //clear check auth cookie status interval.
  if (checkAuthCookieInterval) {
    clearInterval(checkAuthCookieInterval);
    checkAuthCookieInterval = null;
  }
}
//Development auth only.
export async function devAuth({ commit, state }) {
  if (!state.is_user_logged_in && process.env.DEV) {
    let devLogin = null,
      devPass = null;
    try {
      devLogin = process.env.DEV_USER_LOGIN;
      devPass = process.env.DEV_USER_PASS;
    } catch (e) {}
    if (devLogin && devPass) {
      await $API.LOGIN.POST({ login: devLogin, password: devPass })
        .then(({ data }) => {
          commit("setIsUserLoggedIn", true);
          //Always set updated user data.
          commit("setUser", data.user);
          commit("setToken", data.token);
          api.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
          window.location.reload(); //to pull page data again.
        });
    }
  }
}
export function syncActiveWatchlistCount({ commit, dispatch }) {
  $API.CURRENT_USER_WATCHLIST_ACTIVE_COUNT.GET()
    .then(({ data }) => {
      commit("setWatchlistActiveCount", data.count);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncActiveWatchlistCount")
      }, 300);
    });
}
