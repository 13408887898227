export function setForm(state, data) {
  state.form = data;
}
export function setFormValue(state, { prop, value }) {
  state.form[prop] = value;
}
export function mapFormData(state, data) {
  Object.keys(state.form).forEach(prop => {
    if (prop === 'chat') {
      state.form[prop] = NaN;
    } else if (state.form[prop] instanceof Array) {
      state.form[prop] = data[prop] || [];
    } else {
      state.form[prop] = data[prop];
    }
  })
}
export function clearFormData(state) {
  state.form = {
    id: null,
    chat: 0,
    license: null,
    title: "",
    description: "",
    inventory_value: 0,
    inventory_unit: "lb",
    image: {},
    category_type: null,
    category: null,
    strain_type: null,
    production_type: null,
    max_price: null
  };
}
