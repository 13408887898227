export function setIsUserLoggedIn(state, value) {
  state.is_user_logged_in = value;
}
export function setUser(state, value) {
  state.user = value;
}
export function clearToken(state) {
  state.token = "";
}
export function setToken(state, value) {
  state.token = value;
}
export function setWatchlistActiveCount(state, value) {
  state.watchlistActiveCount = value;
}
