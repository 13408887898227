<template>
  <router-view />
</template>
<script>
import { defineComponent } from "vue";
import { useQuasar } from "quasar";
import iconSet from "src/icons/iconset.js";

export default {
  name: "App",
  setup() {
    const $q = useQuasar();

    $q.iconSet.set(iconSet);
  },
  mounted() {
    if (process.env.APP_TYPE === 'native') {
      //Close splash screen (mobile native apps).
      this.$appNative?.closeSplashScreen();
    }
    if (this.$q.platform.is.android) {
      window.addEventListener("resize", this.handleAndroidResizeEvent);
    }
    if (this.userData?.id) {
      this.loadUserDataToBugPilot();
    }
  },
  beforeUnmount() {
    if (this.$q.platform.is.android) {
      window.removeEventListener("resize", this.handleAndroidResizeEvent);
    }
  },
  computed: {
    userData() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    handleAndroidResizeEvent() {
      //When android opens keyboard on a focused input, input may become out of viewport.
      //This handling will move a scroll position to a focused input.
      let focusElement = document.activeElement;
      if (
        focusElement &&
        ["input", "textarea", "select"].indexOf(
          focusElement.tagName.toLowerCase()
        ) >= 0
      ) {
        //If input is a part of Quasar field, then focus on it.
        let qField = focusElement.closest(".q-field");
        if (qField) {
          focusElement = qField;
        }
        setTimeout(() => {
          focusElement.scrollIntoView({ block: "end", behavior: "smooth" });
        }, 200); //delay to be sure android keyboard is fully rendered.
      }
    },
    loadUserDataToBugPilot() {
      if (typeof window.Bugpilot !== "undefined") {
        window.Bugpilot.identify({
          id: this.userData.login, // Required
          email: this.userData.email, // Required for help desk integrations
        });
      }
    },
  },
  watch: {
    userData(newData, oldData) {
      if (
        newData?.login !== oldData?.login ||
        newData?.email !== oldData?.email
      ) {
        this.loadUserDataToBugPilot();
      }
    },
  },
};
</script>
