export default function () {
  return {
    form: {
      id: null,
      chat: 0,
      title: "",
      logo: null,
      banner: null,
    },
  };
}
