export function unread(state) {
  return state.notifications.filter((notification) => !notification.is_read);
}
//TODO: replace with an api call to the database
export function search(state) {
  const re = new RegExp(
    `\\b(${state.searchValue
      .split(" ")
      .filter((word) => word)
      .join("|")})\\b`,
    "gi"
  );
  const count = (str) => (str.replace(/[^a-z]/g, " ").match(re) || []).length;
  const arr = state.notifications
    .map((notification) => {
      let data = {};
      data.count = count(notification.data.title) * 2;
      data.count += count(notification.data.text);
      data.notification = notification;

      return data;
    })
    .filter((data) => data.count)
    .sort((a, b) => (a.count > b.count ? -1 : 1))
    .map((data) => data.notification);

  return arr;
}
