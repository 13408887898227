import { $API } from "boot/api";

export function uploadFormImages({ commit, state }, { prop, value }) {
  state.form.images.forEach((image, index) => {
    if (!value.filter((value) => value.name == image.name).length) {
      deleteImage({ commit, state }, index);
    }
  });
  value.forEach((value) => {
    if (value instanceof File) {
      uploadImage({ commit }, value);
    }
  });
}
export function uploadImage({ commit }, imageFile) {
  let formData = new FormData();
  formData.append("file", imageFile, imageFile.name);
  return $API.CURRENT_USER_ATTACHMENTS.POST(formData)
    .then(({ data }) => {
      commit("addImage", data.file);
      return {
        success: true,
        image: { name: data.file.name, url: data.file.url },
      };
    })
    .catch(({ response: { data } }) => {
      return {
        success: false,
        message: "Failed to upload an image",
      };
    });
}
export function deleteImage({ commit, state }, imageIndex) {
  let image = state.form.images[imageIndex];
  let formData = {
    file_name: image.name,
  };

  return $API.CURRENT_USER_ATTACHMENTS_DELETE.POST(formData)
    .then(() => {
      commit("removeImage", imageIndex);
      return {
        success: true,
      };
    })
    .catch(({ response: { data } }) => {
      return {
        success: false,
        message: "Failed to delete an image",
      };
    });
}

export function loadFormData({ commit, state }, id) {
  return $API.PRODUCTS.GET(id)
    .then(({ data }) => {
      commit("mapFormData", data.product);

      return data.product;
    })
    .catch((data) => {
      return data;
    });
}
export function submitFormData({ commit, state, getters }) {
  return $API.PRODUCTS.POST(state.form.id || "", getters.getFormData).then(
    (data) => {
      commit("clearFormData");

      return data;
    }
  );
}

export function logout({ commit }) {
  commit("clearFormData");
}
