export function clearSessionState({ commit }) {
  commit("setSearch", "");
  commit("setCategoryType", null);
  commit("setCategory", "");
  commit("setInventoryGroupingAvailable", false);
  commit("setInventoryGroupingValue", null);
}

export function setListingAction({ commit }, action) {
  commit("setListingAction", action);
}

export function setEntryPoint({ commit }, action) {
  commit("setEntryPoint", action);
}

export function resetListingAction({ commit }) {
  commit("resetListingAction");
}

export function clearEntryPoint({ commit }) {
  commit("clearEntryPoint");
}
