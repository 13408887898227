export function setForm(state, data) {
  state.form = data;
}
export function setFormValue(state, { prop, value }) {
  state.form[prop] = value;
}

export function addImage(state, image) {
  state.form.images.push(image);
}
export function removeImage(state, imageIndex) {
  state.form.images.splice(imageIndex, 1);
}

export function mapFormData(state, data) {
  Object.keys(state.form).forEach(prop => {
    if (prop == 'chat') {
      state.form[prop] = NaN;
    } else if (!isNaN(state.form[prop])) {
      state.form[prop] = data[prop] || 0;
    } else if (state.form[prop] instanceof Array) {
      state.form[prop] = data[prop] || [];
    } else {
      state.form[prop] = data[prop];
    }
  })
}
export function clearFormData(state) {
  state.form = {
    id: null,
    chat: 0,
    is_edit_product: false,
    listing_draft_id: null,
    save_draft: false,

    //product fields
    product: null,
    title: "",
    description: "",
    inventory_value: 0,
    inventory_unit: "lb",
    images: [],

    //auction fields
    start_date: "",
    start_price: 0,
    days_duration: 10,
    is_auto_relist: false,
    is_reserve_price: false,
    reserve_price: 0,
    is_buy_now_price: false,
    buy_now_price: 0,
    is_promoted: false,
    tags: [],
    bids_count: 0,
    shipping_boxes: [],
    shipping_boxes_value: {},
    payment_terms: [],
    grow_media: [],
  };
}
