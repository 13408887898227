import { $API } from "boot/api";

export function submitFormData({ commit, state, getters }, extraData) {
  let formData = { ...getters.getTransferFormData, ...extraData };

  return $API.TRANSFERS.POST(formData).then((data) => {
    commit("clearFormData");

    return data;
  });
}
