export function setListingCount(state, listingsCounts) {
  state.listingsCount = [];
  state.listingsCount = listingsCounts;
}

export function setItems(state, items) {
  state.items = [];
  state.items = items;
}

export function setPackages(state, packages) {
  state.packages = [];
  state.packages = packages;
}

export function setLastSynced(state, lastSynced) {
  state.lastSynced = "";
  state.lastSynced = lastSynced;
}

export function setItemTotals(state, itemTotals) {
  state.itemTotals = [];
  state.itemTotals = itemTotals;
}
