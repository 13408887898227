import { $API } from "boot/api";

export function uploadFormFile({ commit }, { prop, value }) {
  let formData = new FormData();

  formData.append("file", value, value.name);

  return $API.CURRENT_USER_ATTACHMENTS.POST(formData)
    .then(({ data }) => {
      commit("setFormValue", { prop, value: data.file });

      return {
        success: true,
        file: { name: data.file.name, url: data.file.url },
      };
    })
    .catch(({ response: { data } }) => {
      return {
        success: false,
        message: "Failed to upload a file",
      };
    });
}

export function deleteFormFile({ commit, state }, { prop }) {
  let file = state.form[prop];

  if (file) {
    let formData = {
      file_name: file.name,
    };

    return $API.CURRENT_USER_ATTACHMENTS_DELETE.POST(formData)
      .then(() => {
        commit("setFormValue", { prop, value: null });

        return {
          success: true,
        };
      })
      .catch(({ response: { data } }) => {
        return {
          success: false,
          message: "Failed to delete image",
        };
      });
  } else {
    commit("setFormValue", { prop, value: null });
  }
}

export function uploadFormImages({ commit, state }, { prop, value }) {
  state.form[prop].forEach((image) => {
    if (!value.find((value) => value.path === image.path)) {
      deleteImage({ commit, state }, { prop, image });
    }
  });
  value.forEach((value) => {
    if (value instanceof File) {
      uploadImage({ commit }, { prop, imageFile: value });
    } else if (!state.form[prop].find((image) => image.path === value.path)) {
      commit("addArrayValue", { prop, value });
    }
  });
}

function generateId() {
  const randomPart = Math.floor(Math.random() * 1000);
  return new Date().getTime() + randomPart;
}

export function uploadImage({ commit }, { prop, imageFile }) {
  let formData = new FormData();
  const imageId = generateId();
  formData.append("file", imageFile, imageFile.name);

  return $API.CURRENT_USER_ATTACHMENTS.POST(formData)
    .then(({ data }) => {
      const fileWithId = {
        ...data.file,
        id: imageId,
      };
      commit("addArrayValue", { prop, value: fileWithId });
      return {
        success: true,
        image: { name: data.file.name, url: data.file.url },
      };
    })
    .catch(({ response: { data } }) => {
      return {
        success: false,
        message: "Failed to upload an image",
      };
    });
}

export function deleteImage({ commit, state }, { prop, image }) {
  if (image.path.indexOf("user-uploads/") === 0) {
    let formData = {
      file_name: image.name,
    };
    return $API.CURRENT_USER_ATTACHMENTS_DELETE.POST(formData)
      .then(() => {
        commit("removeArrayValue", {
          prop,
          index: state.form[prop].findIndex((i) => i.path === image.path),
        });
        return {
          success: true,
        };
      })
      .catch(({ response: { data } }) => {
        return {
          success: false,
          message: "Failed to delete an image",
        };
      });
  } else {
    commit("removeArrayValue", {
      prop,
      index: state.form[prop].findIndex((i) => i.path === image.path),
    });
  }
}

export function submitFormData({ commit, state, getters }, extraData) {
  if (state.form.action === "product_only") {
    let formData = { ...getters.getAuctionFormData, ...extraData };

    return $API.PRODUCTS.POST(formData).then((data) => {
      commit("clearFormData");

      return data;
    });
  } else if (state.form.action === "auction") {
    let formData = { ...getters.getAuctionFormData, ...extraData };

    return $API.LISTINGS.POST(formData).then((data) => {
      commit("clearFormData");

      return data;
    });
  } else if (state.form.action === "buyer_request") {
    let formData = { ...getters.getBuyerRequestFormData, ...extraData };

    return $API.BUYER_REQUESTS.POST(formData).then((data) => {
      commit("clearFormData");

      return data;
    });
  }
}

export function loadAuctionFromData(
  { commit },
  { product, metrcItem, metrcPackage, productOnly, draft }
) {
  let dataToSet = {};
  if (product && product.id) {
    console.log("dataToSet", product);
    dataToSet = {
      //Set title/description.
      product: product.id,
      title: product.title,
      description: product.description,
      //Set taxonomies.
      category_type: product.category_type?.id,
      category: product.category?.id,
      retail_type: product.retail_type,
      strain_type: product.strain_type?.id,
      trim_type: product.trim_type,
      trim_machine: product.trim_machine,
      dry_method: product.dry_method,
      grow_media: product.grow_media,
      production_type: product.production_type?.id,
      brand: product.brand?.id,
      //Set expiration/production dates.
      is_product_expire: !!product.expires_at,
      expiration_date: product.expires_at,
      production_date: product.produced_at,
      //Set COA file.
      is_coa_file: !!product.coa_file,
      coa_files: product.coa_files,
      //Set thc/cbd values.
      thc_cbd_range: !!(product.thc_value_max || product.cbd_value_max),
      thc_value: product.thc_value,
      thc_value_max: product.thc_value_max,
      thc_unit: product.thc_unit ?? "%",
      cbd_value: product.cbd_value,
      cbd_value_max: product.cbd_value_max,
      packaged_qty_value: product.packaged_qty_value,
      packaged_qty_unit: product.packaged_qty_unit,
      packaged_container: product.packaged_container,
      cbd_unit: product.cbd_unit ?? "%",
      //Set images.
      images: product.images,
      //Set accepted payment terms.
      payment_terms: product.payment_terms.map((pt) => pt.id),
    };
  } else if (metrcItem && metrcItem.id) {
    dataToSet = {
      item: metrcItem.id,
      //Set title.
      title: metrcItem.name,
      //Set taxonomies.
      category_type: metrcItem.category_type?.id,
      //Set thc/cbd values.
      thc_value: metrcItem.thc_value,
      thc_unit: metrcItem.thc_unit ?? "%",
      cbd_value: metrcItem.cbd_value,
      cbd_unit: metrcItem.cbd_unit ?? "%",
    };
  } else if (draft && draft.listing_draft_id) {
    dataToSet = {
      listing_draft_id: draft.listing_draft_id,
      product: draft.product,
      item: draft.item,
      category_type: draft.category_type,
      category: draft.category,
      retail_type: draft.retail_type,
      is_new_brand: draft.is_new_brand,
      brand: draft.brand,
      brand_logo: draft.brand_logo,
      brand_name: draft.brand_name,
      title: draft.title,
      description: draft.description,
      images: draft.images,
      thc_value: draft.thc_value,
      thc_cbd_range: draft.thc_cbd_range,
      thc_value_max: draft.thc_value_max,
      thc_unit: draft.thc_unit,
      cbd_value: draft.cbd_value,
      cbd_value_max: draft.cbd_value_max,
      cbd_unit: draft.cbd_unit,
      packaged_qty_value: draft.packaged_qty_value,
      packaged_qty_unit: draft.packaged_qty_unit,
      strain_type: draft.strain_type,
      trim_type: draft.trim_type,
      trim_machine: draft.trim_machine,
      dry_method: draft.dry_method,
      production_type: draft.production_type,
      production_date: draft.production_date,
      is_coa_file: draft.is_coa_file,
      coa_files: draft.coa_files,
      is_product_expire: draft.is_product_expire,
      expiration_date: draft.expiration_date,
      package: draft.package,
      is_list_all: draft.is_list_all,
      inventory_value: draft.inventory_value,
      inventory_unit: draft.inventory_unit,
      new_packages: draft.new_packages,
      start_price: draft.start_price,
      start_price_per_unit: draft.start_price_per_unit,
      is_buy_now_price: draft.is_buy_now_price,
      buy_now_price: draft.buy_now_price,
      buy_now_price_per_unit: draft.buy_now_price_per_unit,
      is_reserve_price: draft.is_reserve_price,
      reserve_price: draft.reserve_price,
      reserve_price_per_unit: draft.reserve_price_per_unit,
      is_promoted: draft.is_promoted,
      is_auto_relist: draft.is_auto_relist,
      is_start_now: draft.is_start_now,
      start_date: draft.start_date,
      days_duration: draft.days_duration,
      payment_terms: draft.payment_terms,
      grow_media: draft.grow_media,
      shipping_boxes: draft.shipping_boxes,
      shipping_boxes_value: draft.shipping_boxes_value,
    };
  }

  if (metrcPackage && metrcPackage.tag) {
    dataToSet["package"] = metrcPackage.tag;
  }

  if (productOnly) {
    dataToSet["action"] = "product_only";
  }

  commit("setNewAuctionFormData", dataToSet);
}

export function logout({ commit }) {
  commit("clearFormData");
}
