export function setForm(state, data) {
  state.form = data;
}

export function setFormValue(state, { prop, value }) {
  state.form[prop] = value;
}

export function addArrayValue(state, { prop, value }) {
  state.form[prop].push(value);
}

export function removeArrayValue(state, { prop, index }) {
  state.form[prop].splice(index, 1);
}

export function mapFormData(state, data) {
  Object.keys(state.form).forEach((prop) => {
    if (prop === "chat") {
      state.form[prop] = NaN;
    } else if (!isNaN(state.form[prop])) {
      state.form[prop] = data[prop] || 0;
    } else if (state.form[prop] instanceof Array) {
      state.form[prop] = data[prop] || [];
    } else {
      state.form[prop] = data[prop];
    }
  });
}

export function clearFormData(state) {
  state.form = {
    chat: 0,
    action: null,

    manifestId: null,
    receiverLicense: null,
    receiverAddress: null,
    receiverCity: null,
    receiverState: null,
    receiverZip: null,
    packageName: null,
    packagePrice: null,
    packages: [],
    shipment_shipping_boxes: [],
    shipment_shipping_boxes_value: {},
    addCheckedCooler: false,
    addCheckedClones: false,
    addCheckedOverSized: false,
    addCheckedDropOff: false,
    addCheckedFragile: false,
    addCheckedDryIce: false,
    payment_terms: [],
  };
}
