export default function () {
  return {
    form: {
      id: null,
      chat: 0,
      tag: "",
      title: "",
      description: "",
      inventory_value: 0,
      inventory_unit: "lb",
      brand: null,
      category: null,
      category_type: null,
      strains: [],
      strain_type: null,
      trim_type: null,
      trim_machine: null,
      dry_method: null,
      grow_media: [],
      license: null,
      package: null,
      production_type: null,
      production_date: "",
      has_expiration: false,
      expiration_date: "",
      images: [],
      thc_percent: null,
      cbd_percent: null,
      coa_files: [],
    },
  };
}
