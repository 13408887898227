import { $API } from "boot/api";

export function uploadFormFile({ commit }, { prop, value }) {
  let formData = new FormData();

  formData.append("file", value, value.name);

  return $API.CURRENT_USER_ATTACHMENTS.POST(formData)
    .then(({ data }) => {
      commit("setFormValue", { prop, value: data.file });

      return {
        success: true,
        image: { name: data.file.name, url: data.file.url },
      };
    })
    .catch(({ response: { data } }) => {
      return {
        success: false,
        message: "Failed to upload image",
      };
    });
}
export function deleteFormFile({ commit, state }, { prop }) {
  let file = state.form[prop];
  let formData = {
    file_name: file.name,
  };

  return $API.CURRENT_USER_ATTACHMENTS_DELETE.POST(formData)
    .then(() => {
      commit("setFormValue", { prop, value: null });

      return {
        success: true,
      };
    })
    .catch(({ response: { data } }) => {
      return {
        success: false,
        message: "Failed to delete image",
      };
    });
}

export function loadFormData({ commit, state }, id) {
  return $API.BRANDS.GET(id)
    .then(({ data }) => {
      commit("mapFormData", data.brand);

      return data;
    })
    .catch((data) => {
      return data;
    });
}
export function submitFormData({ commit, state, getters }) {
  return $API.BRANDS.POST(state.form.id || "", getters.getFormData).then(
    (data) => {
      commit("clearFormData");

      return data;
    }
  );
}
