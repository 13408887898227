export function setForm(state, data) {
  state.form = data;
}
export function setFormValue(state, { prop, value }) {
  state.form[prop] = value;
}

export function addImage(state, image) {
  state.form.images.push(image);
}
export function removeImage(state, imageIndex) {
  state.form.images.splice(imageIndex, 1);
}

export function mapFormData(state, data) {
  Object.keys(state.form).forEach((prop) => {
    if (prop == "chat") {
      state.form[prop] = NaN;
    } else if (state.form[prop] instanceof Array) {
      state.form[prop] = data[prop] || [];
    } else {
      state.form[prop] = data[prop];
    }
  });
}
export function clearFormData(state) {
  state.form = {
    id: null,
    chat: 0,
    tag: "",
    title: "",
    description: "",
    inventory_value: 0,
    inventory_unit: "lb",
    brand: null,
    category: null,
    category_type: null,
    strains: [],
    strain_type: null,
    trim_type: null,
    trim_machine: null,
    dry_method: null,
    grow_media: [],
    license: null,
    package: null,
    production_type: null,
    has_expiration: false,
    production_date: "",
    expiration_date: "",
    images: [],
    thc_percent: null,
    cbd_percent: null,
  };
}
