export function getFormData(state) {
  const {
    tag,
    license,
    title,
    description,
    images,
    brand,
    category,
    category_type,
    production_type,
    inventory_value,
    inventory_unit,
    strains,
    strain_type,
    trim_type,
    trim_machine,
    dry_method,
    grow_media,
    thc_percent,
    cbd_percent,
    production_date,
    has_expiration,
    expiration_date,
  } = state.form;

  return {
    tag,
    license: license.id,
    title,
    description,
    images,
    brand: brand.id,
    category: category.id,
    category_type: category_type.id,
    production_type: production_type.id,
    inventory_value,
    inventory_unit,
    strains,
    strain_type: strain_type.id,
    trim_type: trim_type,
    trim_machine: trim_machine,
    dry_method: dry_method,
    grow_media: grow_media,
    thc_percent,
    cbd_percent,
    production_date,
    has_expiration,
    expiration_date,
  };
}
