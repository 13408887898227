import { boot } from "quasar/wrappers";
import Echo from "laravel-echo";
import { api } from "boot/api";
import { Notify } from "quasar";

window.Pusher = require("pusher-js");

export default boot(({ app, store, router }) => {
  const buildEcho = function () {
    // we would be able to use this.$echo in Vue files.
    let echo = new Echo({
      broadcaster: "pusher",
      key: process.env.PUSHER_APP_KEY,
      cluster: process.env.PUSHER_APP_CLUSTER,
      forceTLS: true,
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            api.post("/api/broadcasting/auth", {
              socket_id: socketId,
              channel_name: channel.name,
            })
              .then((response) => {
                callback(false, response.data);
              })
              .catch((error) => {
                callback(true, error);
              });
          },
        };
      },
    });

    echo
      .private("App.Models.User." + store.state.auth.user.id)
      .notification((notification) => {
        //special type of broadcast notification - to delete specific notification.
        if (typeof notification.delete_notification_id !== 'undefined') {
          store.dispatch("notifications/removeNotification", notification.delete_notification_id);
          return;
        }

        store.dispatch("notifications/pushNotification", notification);
      });

    app.config.globalProperties.$echo = echo;
  }

  if (store.state.auth.is_user_logged_in) {
    buildEcho();
  }

  app.config.globalProperties.$buildEcho = buildEcho;
});
